<template>
  <div class="h5Contact">
    <h1>联系我们</h1>
    <h2>Contact us</h2>
    <div class="info">
      <!-- <div class="item">
        <img width="44" height="44" src="./address.png" alt="" />
        <div>
          <p>总部地址</p>
          <p v-for="(item, index) in headquarters" :key="index" style="font-size: 14px">
            {{ item }}
          </p>
        </div>
      </div> -->
      <!-- <div class="item">
        <img width="44" height="44" src="./tel.png" alt="" />
        <div>
          <p>办公地址</p>
          <p v-for="(item, index) in office" :key="index" style="font-size: 14px">
            {{ item }}
          </p>
        </div>
      </div> -->
      <div class="item" v-for="(item, index) in contactData.menuBlockList" :key="index">
        <img width="44" height="44" src="./address.png" alt="" />
        <div>
          <p>{{ item.remark }}</p>
          <p style="font-size: 14px">
            {{ item.sysValue }}
          </p>
        </div>
      </div>
    </div>

    <!-- <baidu-map class="map" :data="mapObj" style="margin-top: 25px"></baidu-map> -->
  </div>
</template>

<script>
// import baiduMap from "@/components/baiduMap/index";
export default {
  components: {
    // baiduMap,
  },
  data() {
    return {
      // 基本信息
      contactData: {
        menuBlockList: [],
      },
      // 地图基本信息
      mapObj: {},
    };
  },
  async created() {
    let { status, data } = await this.$api.home.getContact();
    if (status == 200) {
      // 处理结果集块元素集合
      let menuBlockList = []; // 页面展示基本元素
      for (let key in data) {
        // 指定放在第一个位置
        if (key == "BUS_ADDRESS") {
          if (data[key].sysValue) {
            menuBlockList.splice(0, 0, data[key]);
          }
        }
        // 指定放在第一个位置
        if (key == "HQ_ADDRESS") {
          if (data[key].sysValue) {
            menuBlockList.splice(1, 0, data[key]);
          }
        }
        // 接口已经自动排序 按顺序放就可以
        if (
          key == "EXT1" ||
          key == "EXT2" ||
          key == "EXT3" ||
          key == "EXT4" ||
          key == "EXT5"
        ) {
          if (data[key].sysValue) {
            menuBlockList.push(data[key]);
          }
        }
        // 处理地图坐标
        if (key == "MAP_COORD") {
          if (data[key].sysValue) {
            this.mapObj.MAP_COORD = data[key].sysValue;
          }
        }
        // 联系人
        if (key == "LINK_PERSON") {
          if (data[key].sysValue) {
            this.mapObj.LINK_PERSON = data[key].sysValue;
          }
        }
        // 邮箱
        if (key == "LINK_EMAIL") {
          if (data[key].sysValue) {
            this.mapObj.LINK_EMAIL = data[key].sysValue;
          }
        }
        // 地图上显示的联系地址
        if (key == "LINK_ADDRESS") {
          if (data[key].sysValue) {
            this.mapObj.LINK_ADDRESS = data[key].sysValue;
          }
        }
      }
      this.contactData.menuBlockList = menuBlockList; // 页面基本元素
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMap = true;
    });
  },
};
</script>

<style lang="scss">
.h5Contact {
  padding-top: 65px;
  padding-left: 20px;
  padding-right: 20px;
  h1 {
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  }
  h2 {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    text-align: center;
    line-height: 17px;
  }
  .info {
    background: #ffffff;
    box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.06);
    margin-top: 20px;
    padding: 20px;
    padding-top: 10px;
    .item {
      display: flex;
      padding-bottom: 16px;
      margin-top: 16px;
      border-bottom: 1px solid #e4e4e4;
      img {
        flex-basis: 44px;
      }
      div {
        flex: 1;
        padding-left: 11px;
        p:first-child {
          font-size: 16px;
          color: #333;
          line-height: 21px;
          font-weight: bold;
          margin-top: 1px;
        }
        // p:last-child {
        //   line-height: 21px;
        //   font-size: 12px;
        //   color: #666;
        // }
      }
      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
    }
  }
  .BMap_pop,
  .BMap_shadow {
    display: none;
  }
}
</style>
